
import React from "react";
import { useTranslation } from "react-i18next";
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./history.scss";

const History = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("history:metaTitle")}>
      <>
        <HeroImg customClass="history-hero" subtitle={t("history:heroSubtitle")} title={t("history:heroTitle")} backgroundImage="bg/history-hero.jpg"/>

        <div className="history">

          <div className="history-section-one">
            <p className="box-two"> {t("history:sectionOneText")}</p>
            <p>{t("history:sectionOneTextTwo")}</p>
          </div>

          <div className="history-section-two">

            <div className="first-section">
              <div className="left">
                <img src="/img/history-one.jpg" alt="history-one" />
              </div>
              <div className="right">
                <div>
                  <h5>{t("history:sectionTwoSubtitle")}</h5>
                  <h3 className="box-two">{t("history:sectionTwoTitle")}</h3>
                  <p>{t("history:sectionTwoText")}</p>
                </div>
              </div>
            </div>

            <div className="second-section">

              <div className="right">
                <div>
                  <h5>{t("history:sectionThreeSubtitle")}</h5>
                  <h3 className="box-two">{t("history:sectionThreeTitle")}</h3>
                  <p>{t("history:sectionThreeText")}</p>
                </div>
              </div>
              <div className="left">
                <img src="/img/history-two.jpg" alt="history-two" />
              </div>
            </div>

            <div className="first-section">
              <div className="left">
                <img src="/img/history-three.jpg" alt="history-three" />
              </div>
              <div className="right">
                <div>
                  <h3 className="box-two">{t("history:sectionFourTitle")}</h3>
                  <p>{t("history:sectionFourText")}</p>
                </div>
              </div>
            </div>

            <div className="second-section">

              <div className="right">
                <div>
                  <h3 className="box-two">{t("history:sectionFiveTitle")}</h3>
                  <p>{t("history:sectionFiveText")}</p>
                </div>
              </div>
              <div className="left">
                <img src="/img/history-four.jpg" alt="history-four" />
              </div>
            </div>
          </div>

          <div className="history-bottom-container">
            <HeroImg customClass="history-bottom" backgroundImage="bg/history-bottom.jpg">
              {<h3 className="history-bottom-text">{t("history:sectionSixText")}</h3>
              }
            </HeroImg>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default History;
